export const cities = [
  { city: "Alameda", rate: 940, state: "CA" },
  { city: "American Canyon", rate: 430, state: "CA" },
  { city: "Arcata", rate: 1600, state: "CA" },
  { city: "Atwater", rate: 730, state: "CA" },
  { city: "Bakersfield", rate: 1470, state: "CA" },
  { city: "Bay Point", rate: 440, state: "CA" },
  { city: "Belmont", rate: 420, state: "CA" },
  { city: "Benicia", rate: 340, state: "CA" },
  { city: "Brisbane", rate: 860, state: "CA" },
  { city: "Burlingame", rate: 390, state: "CA" },
  { city: "Castro Valley", rate: 530, state: "CA" },
  { city: "Castroville", rate: 800, state: "CA" },
  { city: "Ceres", rate: 630, state: "CA" },
  { city: "Chico", rate: 1250, state: "CA" },
  { city: "Clarksburg", rate: 600, state: "CA" },
  { city: "Cloverdale", rate: 590, state: "CA" },
  { city: "Coalinga", rate: 1020, state: "CA" },
  { city: "Colma", rate: 1440, state: "CA" },
  { city: "Concord", rate: 390, state: "CA" },
  { city: "Crow's Landing", rate: 600, state: "CA" },
  { city: "Cupertino", rate: 530, state: "CA" },
  { city: "Diamond Springs", rate: 750, state: "CA" },
  { city: "Dinuba", rate: 1140, state: "CA" },
  { city: "Dixon", rate: 750, state: "CA" },
  { city: "Dos Palos", rate: 730, state: "CA" },
  { city: "Dunnigan", rate: 630, state: "CA" },
  { city: "Escalon", rate: 590, state: "CA" },
  { city: "Exeter", rate: 1250, state: "CA" },
  { city: "Fairfield", rate: 490, state: "CA" },
  { city: "Firebaugh", rate: 860, state: "CA" },
  { city: "Fontana", rate: 980, state: "CA" },
  { city: "Fremont", rate: 400, state: "CA" },
  { city: "French Camp", rate: 440, state: "CA" },
  { city: "Fresno", rate: 1000, state: "CA" },
  { city: "Galt", rate: 650, state: "CA" },
  { city: "Gilroy", rate: 520, state: "CA" },
  { city: "Grass Valley", rate: 600, state: "CA" },
  { city: "Gridley", rate: 800, state: "CA" },
  { city: "Gustine", rate: 680, state: "CA" },
  { city: "Hanford", rate: 1200, state: "CA" },
  { city: "Hayward", rate: 340, state: "CA" },
  { city: "Healdsburg", rate: 830, state: "CA" },
  { city: "Hickman", rate: 650, state: "CA" },
  { city: "Hilmar", rate: 670, state: "CA" },
  { city: "Hollister", rate: 650, state: "CA" },
  { city: "Hughson", rate: 590, state: "CA" },
  { city: "Ione", rate: 740, state: "CA" },
  { city: "Kettleman City", rate: 1200, state: "CA" },
  { city: "King City", rate: 670, state: "CA" },
  { city: "Las Vegas", rate: 540, state: "NV" },
  { city: "Lathrop", rate: 540, state: "CA" },
  { city: "Lincoln", rate: 750, state: "CA" },
  { city: "Lindsay", rate: 1260, state: "CA" },
  { city: "Live Oak", rate: 890, state: "CA" },
  { city: "Livermore", rate: 860, state: "CA" },
  { city: "Lodi", rate: 850, state: "CA" },
  { city: "Los Altos Hill", rate: 850, state: "CA" },
  { city: "Los Banos", rate: 720, state: "CA" },
  { city: "Los Gatos", rate: 640, state: "CA" },
  { city: "Lost Hills", rate: 1300, state: "CA" },
  { city: "Madera", rate: 1390, state: "CA" },
  { city: "Manteca", rate: 530, state: "CA" },
  { city: "McClellan", rate: 620, state: "CA" },
  { city: "Mendota", rate: 850, state: "CA" },
  { city: "Merced", rate: 740, state: "CA" },
  { city: "Milpitas", rate: 410, state: "CA" },
  { city: "Minden", rate: 1350, state: "NV" },
  { city: "Modesto/Riverbank", rate: 600, state: "CA" },
  { city: "Morgan Hill", rate: 500, state: "CA" },
  { city: "Mound House", rate: 1370, state: "NV" },
  { city: "Mountain View", rate: 450, state: "CA" },
  { city: "Napa", rate: 470, state: "CA" },
  { city: "Newark", rate: 360, state: "CA" },
  { city: "North Highlands", rate: 860, state: "CA" },
  { city: "Novato", rate: 440, state: "CA" },
  { city: "Oakdale", rate: 550, state: "CA" },
  { city: "Oakland", rate: 290, state: "CA" },
  { city: "Olivehurst", rate: 740, state: "CA" },
  { city: "Pacifica", rate: 840, state: "CA" },
  { city: "Palo Alto", rate: 850, state: "CA" },
  { city: "Patterson", rate: 650, state: "CA" },
  { city: "Petaluma", rate: 450, state: "CA" },
  { city: "Pittsburg", rate: 420, state: "CA" },
  { city: "Pleasant Hill", rate: 450, state: "CA" },
  { city: "Rancho Cordova", rate: 630, state: "CA" },
  { city: "Red Bluff", rate: 1110, state: "CA" },
  { city: "Redding", rate: 1250, state: "CA" },
  { city: "Reno", rate: 1300, state: "NV" },
  { city: "Richmond", rate: 330, state: "CA" },
  { city: "Ripon", rate: 550, state: "CA" },
  { city: "Rocklin", rate: 790, state: "CA" },
  { city: "Roseville", rate: 900, state: "CA" },
  { city: "Sacramento", rate: 620, state: "CA" },
  { city: "Salida", rate: 530, state: "CA" },
  { city: "Salinas", rate: 650, state: "CA" },
  { city: "San Francisco", rate: 310, state: "CA" },
  { city: "San Jose", rate: 450, state: "CA" },
  { city: "San Leandro", rate: 330, state: "CA" },
  { city: "San Lorenzo", rate: 320, state: "CA" },
  { city: "San Luis Obispo", rate: 1260, state: "CA" },
  { city: "San Mateo", rate: 340, state: "CA" },
  { city: "Sanger", rate: 1000, state: "CA" },
  { city: "Santa Barbara", rate: 1260, state: "CA" },
  { city: "Santa Clara", rate: 420, state: "CA" },
  { city: "Santa Rosa", rate: 530, state: "CA" },
  { city: "Sebastopol", rate: 1030, state: "CA" },
  { city: "Selma", rate: 1030, state: "CA" },
  { city: "Sonoma", rate: 490, state: "CA" },
  { city: "Sonora", rate: 770, state: "CA" },
  { city: "Soquel", rate: 630, state: "CA" },
  { city: "South San Francisco", rate: 370, state: "CA" },
  { city: "Sparks", rate: 1350, state: "NV" },
  { city: "St Helena", rate: 1100, state: "CA" },
  { city: "Stockton", rate: 650, state: "CA" },
  { city: "Sunnyvale", rate: 530, state: "CA" },
  { city: "Tracy", rate: 750, state: "CA" },
  { city: "Tulare", rate: 1240, state: "CA" },
  { city: "Turlock", rate: 640, state: "CA" },
  { city: "Ukiah", rate: 670, state: "CA" },
  { city: "Union City", rate: 360, state: "CA" },
  { city: "Vacaville", rate: 500, state: "CA" },
  { city: "Vallejo", rate: 500, state: "CA" },
  { city: "Visalia", rate: 1180, state: "CA" },
  { city: "Watsonville", rate: 610, state: "CA" },
  { city: "West Sacramento", rate: 630, state: "CA" },
  { city: "Wheatland", rate: 730, state: "CA" },
  { city: "Woodland", rate: 790, state: "CA" },
  { city: "Yerington", rate: 1550, state: "NV" },
  { city: "Yuba City", rate: 740, state: "CA" },
];
